import React from 'react'
import LogoIcon from '../../assets/images/roundlogo.png'
export default function Comingsoon() {
 

  return (
    <>
    	<img width={'65px'} src={LogoIcon} alt="logo" />
      <h1>Coming Soon</h1>
    </>
  )
}
