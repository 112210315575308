//import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'
import uImage from '../assets/images/body_bg.jpg'
import ubear from '../assets/images/bear_bg.png'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',

    // backgrounds / greys
    bg1: darkMode ? '#24262e' : '#ffffff',
    bg2: darkMode ? '#2C2F36' : '#e8e8e8',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#24262e' : '#fff9ee',
    bodybg: darkMode ? '#1a1c21' : '#ffffff',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: darkMode ? '#050507' : '#050507',
    primary2: darkMode ? '#3680E7' : '#FF8CC3',
    primary3: darkMode ? '#4D8FEA' : '#FF99C9',
    primary4: darkMode ? '#376bad70' : '#F6DDE8',
    primary5: darkMode ? '#050507' : '#050507',

    // color text
    primaryText1: darkMode ? '#ffffff' : '#ffffff',

    // secondary colors
    secondary1: darkMode ? '#050507' : '#050507',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#050507',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: darkMode ? '#feb022' : '#feb022',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Merriweather', serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Merriweather', serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}


* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}
body {
  position: relative;
}
body::before {
  content: "";
  background: url(${uImage}) no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  filter: opacity(0.1);
}
body::after {
  content: "";
  background: url(${ubear}) no-repeat;  
  position: absolute;
  width: 690px;
  height: 735px;  
  filter: opacity(0.8);
  background-position: bottom left;
  background-size: 50%;
  bottom: 30px;
  left: 30px;
}
@media screen and (max-width:767px) {
  body::after {
    display: none;
  }
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bodybg};
}
body {
  min-height: 100vh;
  line-height: 24px;
}
a {
  color: ${({ theme }) => theme.blue1} !important;
}
#create-pair-button,
div#swap-currency-input button,
div#swap-currency-output button,
div#add-liquidity-input-tokena button {
  background: #050507;
  border-color: #050507;
}
.whiteBg a.ACTIVE {
  color: #feb022 !important;
}
.whiteBg a {
  color: ${({ theme }) => theme.text1} !important;
}
.custom_text {
  color: ${({ theme }) => theme.text1} !important;
}
.token-symbol-container {
  color: #fff;
}
.open-currency-select-button span > svg > path {
  stroke: #fff;
}

.ct-particle-animate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.ct-particle-animate div {
  position: absolute;
  top: 0;
  left: 0;
}
.ct-particle-animate #ct_particle_animate-a461fd71 {
  left: 7%;
  top: 18%;
}
.ct-particle-animate .shape-animate1 {
  animation: animationFramesTwo 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd72 {
  left: 25%;
  top: 10%;
}
.ct-particle-animate .shape-animate2 {
  animation: animationFramesOne 15s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd73 {
  left: 0%;
  top: 45%;
}
.ct-particle-animate #ct_particle_animate-a461fd83 {
  left: 18%;
  top: 15%;
}
.ct-particle-animate .shape-animate3 {
  animation: animationFramesOne 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd74 {
  left: 72%;
  top: 10%;
}
.ct-particle-animate #ct_particle_animate-a461fd84 {
  left: 70%;
  top: 25%;
}
.ct-particle-animate .shape-animate4 {
  animation: animationFramesFour 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd75 {
  left: 74%;
  top: 24%;
}
.ct-particle-animate .shape-animate5 {
  animation: animationFramesThree 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd77 {
  left: 20%;
  top: 70%;
}
.ct-particle-animate #ct_particle_animate-a461fd87 {
  left: 20%;
  top: 35%;
}
.ct-particle-animate .shape-left-right {
  animation: NewsletterAnimated2 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd76 {
  left: 90%;
  top: 75%;
}
.ct-particle-animate #ct_particle_animate-a461fd86 {
  left: 90%;
  top: 29%;
}
.ct-particle-animate .shape-right-left {
  animation: NewsletterAnimated1 12s infinite linear alternate;
}
@keyframes animationFramesOne {
  0% {
      transform: translate(0, 0) rotate(0);
  }
  20% {
      transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
      transform: translate(111px, 72px) rotate(72deg);
  }
  60% {
      transform: translate(93px, 122px) rotate(108deg);
  }
  80% {
      transform: translate(-70px, 72px) rotate(124deg);
  }
  100% {
      transform: translate(0, 0) rotate(0);
  }
}
@-webkit-keyframes animationFramesOne {
  0% {
      -webkit-transform: translate(0, 0) rotate(0);
  }
  20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
      -webkit-transform: translate(111px, 72px) rotate(72deg);
  }
  60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
      -webkit-transform: translate(-40px, 72px) rotate(124deg);
  }
  100% {
      -webkit-transform: translate(0, 0) rotate(0);
  }
}
@keyframes animationFramesTwo {
  0% {
      transform: translate(0, 0) rotate(0) scale(1);
  }
  20% {
      transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
      transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
      transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
      transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
  }
  100% {
      transform: translate(0, 0) rotate(0) scale(1);
  }
}
@-webkit-keyframes animationFramesTwo {
  0% {
      -webkit-transform: translate(0, 0) rotate(0) scale(1);
  }
  20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
      -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
      -webkit-transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
  }
  100% {
      -webkit-transform: translate(0, 0) rotate(0) scale(1);
  }
}
@keyframes animationFramesThree {
  0% {
      transform: translate(165px, -179px);
  }
  100% {
      transform: translate(-346px, 200px);
  }
}
@-webkit-keyframes animationFramesThree {
  0% {
      -webkit-transform: translate(165px, -179px);
  }
  100% {
      -webkit-transform: translate(-346px, 200px);
  }
}
@keyframes animationFramesFour {
  0% {
      transform: translate(-300px, 151px) rotate(0);
  }
  100% {
      transform: translate(251px, -200px) rotate(180deg);
  }
}
@-webkit-keyframes animationFramesFour {
  0% {
      -webkit-transform: translate(-300px, 151px) rotate(0);
  }
  100% {
      -webkit-transform: translate(251px, -200px) rotate(180deg);
  }
}
@keyframes animationFramesFive {
  0% {
      transform: translate(61px, -99px) rotate(0);
  }
  21% {
      transform: translate(4px, -190px) rotate(38deg);
  }
  41% {
      transform: translate(-139px, -200px) rotate(74deg);
  }
  60% {
      transform: translate(-263px, -164px) rotate(108deg);
  }
  80% {
      transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
      transform: translate(-1px, 0) rotate(180deg);
  }
}
@-webkit-keyframes animationFramesFive {
  0% {
      -webkit-transform: translate(61px, -99px) rotate(0);
  }
  21% {
      -webkit-transform: translate(4px, -190px) rotate(38deg);
  }
  41% {
      -webkit-transform: translate(-139px, -200px) rotate(74deg);
  }
  60% {
      -webkit-transform: translate(-263px, -164px) rotate(108deg);
  }
  80% {
      -webkit-transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
      -webkit-transform: translate(-1px, 0) rotate(180deg);
  }
}
@keyframes animationFramesSix {
  0% {
      transform: translate(31px, -45px) rotate(0);
  }
  21% {
      transform: translate(4px, -80px) rotate(19deg);
  }
  41% {
      transform: translate(-70px, -100px) rotate(36deg);
  }
  60% {
      transform: translate(-133px, -84px) rotate(54deg);
  }
  80% {
      transform: translate(-98px, -24px) rotate(72deg);
  }
  100% {
      transform: translate(-1px, 0) rotate(180deg);
  }
}
@keyframes NewsletterAnimated1 {
  0% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(-40px);
  }
  50% {
      transform: translateX(0);
  }
  75% {
      transform: translateX(40px);
  }
  100% {
      transform: translateX(0);
  }
}
@keyframes NewsletterAnimated2 {
  0% {
      transform: translateX(0);
  }
  25% {
      transform: translateX(40px);
  }
  50% {
      transform: translateX(0);
  }
  75% {
      transform: translateX(-40px);
  }
  100% {
      transform: translateX(0);
  }
}


`
