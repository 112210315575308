import React, { useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import { Pair, JSBI } from "@uniswap/sdk";
import { Link } from "react-router-dom";
import { SwapPoolTabs } from "../../components/NavigationTabs";

import FullPositionCard from "../../components/PositionCard";
import { useUserHasLiquidityInAllTokens } from "../../data/V1";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import { StyledInternalLink, TYPE, HideSmall } from "../../theme";
import { Text } from "rebass";
import Card from "../../components/Card";
import { RowBetween, RowFixed } from "../../components/Row";
import { ButtonPrimary, ButtonSecondary } from "../../components/Button";
import { AutoColumn } from "../../components/Column";

import { useActiveWeb3React } from "../../hooks";
import { usePairs } from "../../data/Reserves";
import {
  toV2LiquidityToken,
  useTrackedTokenPairs,
} from "../../state/user/hooks";
import { Dots } from "../../components/swap/styleds";
import {
  CardSection,
  DataCard,
  CardNoise,
  CardBGImage,
} from "../../components/earn/styled";
import { useStakingInfo } from "../../state/stake/hooks";
import { BIG_INT_ZERO } from "../../constants";

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`;

const VoteCard = styled(DataCard)`
  background: radial-gradient(
    76.02% 75.41% at 1.84% 0%,
    #303236 0%,
    #36393f 100%
  );
  overflow: hidden;
`;

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  color: #fff;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`;

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function Pool() {
  const theme = useContext(ThemeContext);
  const { account } = useActiveWeb3React();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );
  const [
    v2PairsBalances,
    fetchingV2PairBalances,
  ] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan("0")
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens)
  );
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  const hasV1Liquidity = useUserHasLiquidityInAllTokens();

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo();
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO)
  );
  const stakingPairs = usePairs(
    stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens)
  );

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(
    (v2Pair) => {
      return (
        stakingPairs
          ?.map((stakingPair) => stakingPair[1])
          .filter(
            (stakingPair) =>
              stakingPair?.liquidityToken.address ===
              v2Pair.liquidityToken.address
          ).length === 0
      );
    }
  );

  return (
    <>
      {/* <div className="ct-particle-animate">
        <div id="ct_particle_animate-a461fd71" className="shape-animate1">
          <img width="102" height="102" src={require("../../assets/images/shape_01.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd72" className="shape-animate2">
          <img width="45" height="45" src={require("../../assets/images/shape_02.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd73" className="shape-animate3">
          <img width="36" height="36" src={require("../../assets/images/shape_03.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd74" className="shape-animate4">
          <img width="80" height="80" src={require("../../assets/images/shape_04.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd75" className="shape-animate5">
          <img width="24" height="24" src={require("../../assets/images/shape_05.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd77" className="shape-left-right">
          <img width="18" height="18" src={require("../../assets/images/shape_06.png")}  alt="Shape" />
        </div>
        <div id="ct_particle_animate-a461fd76" className="shape-right-left">
          <img width="20" height="20" src={require("../../assets/images/shape_07.png")}  alt="Shape" />
        </div>
      </div> */}
      <PageWrapper>
        <SwapPoolTabs active={"pool"} />
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>
                  Liquidity provider rewards
                </TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  {`Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.`}
                </TYPE.white>
              </RowBetween>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>

        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: "100%" }}>
            <TitleRow style={{ marginTop: "1rem" }} padding={"0"}>
              <HideSmall>
                <TYPE.mediumHeader
                  style={{ marginTop: "0.5rem", justifySelf: "flex-start" }}
                >
                  Your liquidity
                </TYPE.mediumHeader>
              </HideSmall>
              <ButtonRow>
                <ResponsiveButtonSecondary
                  as={Link}
                  padding="6px 8px"
                  to="/create/ETH"
                  id="create-pair-button"
                >
                  Create a pair
                </ResponsiveButtonSecondary>
                <ResponsiveButtonPrimary
                  id="join-pool-button"
                  as={Link}
                  padding="6px 8px"
                  borderRadius="12px"
                  to="/add/ETH"
                >
                  <Text fontWeight={500} fontSize={16}>
                    Add Liquidity
                  </Text>
                </ResponsiveButtonPrimary>
              </ButtonRow>
            </TitleRow>

            {!account ? (
              <Card padding="40px">
                <TYPE.body color={theme.text3} textAlign="center">
                  Connect to a wallet to view your liquidity.
                </TYPE.body>
              </Card>
            ) : v2IsLoading ? (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  <Dots>Loading</Dots>
                </TYPE.body>
              </EmptyProposals>
            ) : allV2PairsWithLiquidity?.length > 0 ||
              stakingPairs?.length > 0 ? (
              <>
                {v2PairsWithoutStakedAmount.map((v2Pair) => (
                  <FullPositionCard
                    key={v2Pair.liquidityToken.address}
                    pair={v2Pair}
                  />
                ))}
                {stakingPairs.map(
                  (stakingPair, i) =>
                    stakingPair[1] && ( // skip pairs that arent loaded
                      <FullPositionCard
                        key={stakingInfosWithBalance[i].stakingRewardAddress}
                        pair={stakingPair[1]}
                        stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                      />
                    )
                )}
              </>
            ) : (
              <EmptyProposals>
                <TYPE.body color={theme.text3} textAlign="center">
                  No liquidity found.
                </TYPE.body>
              </EmptyProposals>
            )}

            <AutoColumn justify={"center"} gap="md">
              <Text
                textAlign="center"
                fontSize={14}
                style={{ padding: ".5rem 0 .5rem 0" }}
              >
                {hasV1Liquidity
                  ? "Uniswap V1 liquidity found!"
                  : "Don't see a pool you joined?"}{" "}
                <StyledInternalLink
                  id="import-pool-link"
                  to={hasV1Liquidity ? "/migrate/v1" : "/find"}
                >
                  {hasV1Liquidity ? "Migrate now." : "Import it."}
                </StyledInternalLink>
              </Text>
            </AutoColumn>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  );
}
